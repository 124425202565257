export default function Info() {
    return (
        <div className={"container section info"}>
            <div className={"about-title title"}>
            </div>
            <div className={"info-list text"}>
                <ul>
                    <li>
                        Mietschläger und Bälle stehen vor Ort zur Verfügung.
                    </li>
                    <li>
                        Getränke gibt’s rund um die Uhr – wir haben einen Outdoor-Kühlschrank.
                    </li>
                    <li>
                        Unsere Grillstelle kannst du kostenlos nutzen. Holz steht zur Verfügung.
                    </li>
                </ul>
                <ul>
                    <li>
                        Eine Toilette ist vorhanden. Die Duschen folgen mit dem Hallenbetrieb.
                    </li>
                    <li>
                        Falls niemand von uns vor Ort ist: Weitere Infos erhältst du in der Buchungsbestätigung.
                    </li>
                </ul>
            </div>
            <div className={"break"}></div>
        </div>
    );
}