import familypicture from "../assets/images/familypicture.jpg";
import gruppenbild from "../assets/images/gruppenbild.jpg";

export default function Who() {
    return (
        <div className="who section">
            <div className="family-title title">
            </div>
            <div className="text who-text">
                <p>
                    Die Sportliche und die Kulinarische: Hinter elPadel stecken die zwei Winterthurer Familien Widmer
                    und Mastai.<br/>
                    Mit viel Herzblut bringen sie Padel in ihre Lieblingsstadt und verschmelzen die beliebte Sportart
                    mit Golf und
                    kulinarischen Happenings.<br/><br/> elPadel soll ein Treffpunkt für Sport, Spiel und Spass werden.
                    Manuel und Melanie Akanji komplettieren das Team. Der internationale Fussballstar und
                    seine
                    Frau Melanie sind Co-Owner –
                    und ebenfalls stolze Winterthurer. Damit passen sie perfekt in die elPadel-Familie.
                </p>
            </div>
            <div className="family-picture">
                <img src={familypicture} alt={"Die Familien Mastai und Widmer"} className={"image"}></img>
            </div>
            <div className="group-picture">
                <img src={gruppenbild} alt={"Nicola Widmer, Seraina Mastai mit Manuel & Melanie Akanji"}
                     className={"image"}></img>
            </div>
            <div className="break">
            </div>
        </div>
    );
}