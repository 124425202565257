import hand from "../assets/images/finger.png"
import booknow from "../assets/images/jetzt_buchen_rot.gif"

export default function Facts() {
    return (<div className="container section facts">
            <div className="break">
            </div>
            <div className="book-now">
                <a href={"https://buchen.elpadel.ch"} target={"_blank"} rel={"noreferrer"}>
                    <img src={booknow} alt={"Jetzt buchen!"} className={"book-now-gif"}></img>
                </a>
            </div>
            <div className={"kacheln"}>
                <div className={"kachel-container"}>
                    <div className="eroeffnung1 kachel">
                    </div>
                    <img src={hand} alt={"Finger"} className={"hand links"}></img>
                </div>
                <div className={"kachel-container"}>
                    <div className="eroeffnung2 kachel">
                    </div>
                    <img src={hand} alt={"Finger"} className={"hand rechts"}></img>
                </div>
            </div>
        </div>
    );
}