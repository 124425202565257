export default function Intro() {
    return (
        <div className="headline">
            <div className="intro-image">
                <a href={"https://buchen.elpadel.ch"} target={"_blank"} rel={"noreferrer"}>
                    <div className="book-now-one">
                    </div>
                </a>
            </div>
            <div className="intro-text">
                <p className="intro1">Mit elPadel erhält Winterthur seine</p>
                <p className="intro2">erste Padel-Anlage &ndash; und noch mehr: </p>
                <p className="bold">eine Spitzenlocation für Events aller Art</p>
            </div>
        </div>

    )
        ;
}