export default function Footer() {
    return (
        <div className={"footer-container"}>
            <div className={"footer-links"}>
                <p className={"link"}><a href={"https://buchen.elpadel.ch"}
                                         target={"_blank"} rel={"noreferrer"}>Platz buchen</a></p><br/>
                <p className={"link"}><a href={"https://eepurl.com/ini5h2"} target={"_blank"}
                                         rel={"noreferrer"}>Newsletter</a></p>
                <p className={"link"}><a href={"https://www.instagram.com/elpadel_socialclub/"}
                                         target={"_blank"} rel={"noreferrer"}>Instagram</a></p>
                <p className={"link"}><a href={"https://www.facebook.com/elpadel.socialclub"}
                                         target={"_blank"} rel={"noreferrer"}>Facebook</a></p>
                <p className={"link"}><a href={"https://www.linkedin.com/company/elpadel/about/"}
                                         target={"_blank"} rel={"noreferrer"}>Linkedin</a></p>
            </div>
            <div className={"contact-container"}>
                <p className={"link"}><a href={"https://elpadel.ch/elPadel_Medienmitteilung_Akanji.pdf"}
                                         target={"_blank"} rel={"noreferrer"}>Medienmitteilung Akanji</a></p>
                <p className={"link"}><a href={"https://elpadel.ch/230331_Medienmitteilung_elPadel_Landingpage.pdf"}
                                         target={"_blank"} rel={"noreferrer"}>Medienmitteilung Projekt</a></p><br/>

                <p>elPadel AG</p>
                <p>Nicola Widmer</p>
                <p>Sandgrubenstrasse 3</p>
                <p>8409 Winterthur</p>
                <p className={"link"}><a href={"mailto:info@elpadel.ch"}>info@elpadel.ch</a></p>
            </div>
        </div>
    );
}