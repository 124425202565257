export default function News() {
    return (<div className={"news text"}>
            <div className="news-title title">
            </div>
            <div className="news-boxes">
                <div className="news-box ">
                    <p className={"bold"}>Padelturnier Mexicano</p>
                    <p>Spiele zwei Stunden Padel und lerne neue Spieler:innen kennen.</p>
                    <p><a href={"/elPadel_MexicanoTournament.pdf"} target={"_blank"} rel={"noreferrer"}>Mehr Infos</a>
                    </p>
                </div>
                <div className="news-box">
                    <p className={"bold"}>Padel-Lektionen</p>
                    <p>Verbessere deine Padel-Skills für noch mehr Spielspass.</p>
                    <p><a href={"/Padel_Lektionen_Infos.pdf"} target={"_blank"} rel={"noreferrer"}>Mehr Infos</a></p>
                </div>
                <div className="news-box">
                    <p className={"bold"}>Kids-Padel</p>
                    <p>Kurse am Mittwochnachmittag für Kids im Alter von 6 bis 15 Jahren.</p>
                    <p><a href={"/Kids-Padel_online.pdf"} target={"_blank"} rel={"noreferrer"}>Mehr Infos</a></p>
                    <p><a href={"https://app.cituro.com/booking/elpadel-kids-padel"} target={"_blank"}
                          rel={"noreferrer"}>Jetzt
                        anmelden</a>
                    </p>
                </div>
            </div>
            <div className="break"></div>
        </div>
    );
}