import visual1 from "../assets/images/visual1.jpg"
import visual2 from "../assets/images/visual2.jpg"

export default function Visuals() {
    return (
        <div className="visuals">
            <img className="visual" src={visual2} alt={"Visual1"}></img>
            <img className="visual" src={visual1} alt={"Visual2"}></img>
        </div>
    );
}