import logo from '../assets/images/logo.png';

export default function Header() {
    return (
        <header className="header">
            <div className="header-logo">
                <img className="logo" src={logo} alt="logo"></img>
            </div>
        </header>
    );
}