import './App.css';
import Header from './components/Header';
import Intro from "./components/Intro";
import Facts from "./components/Facts";
import Visuals from "./components/Visuals";
import Padel from "./components/Padel";
import Who from "./components/Who";
import ReactGA from "react-ga4";
import News from "./components/News";
import Info from "./components/Info";
import Footer from "./components/Footer";

ReactGA.initialize("G-CGN8W2NTES")

function App() {
    return (
        <div className="App">
            <Header/>
            <Intro/>
            <Facts/>
            <Visuals/>
            <Info/>
            <News/>
            <Who/>
            <Padel/>
            <Footer/>
        </div>
    );
}

export default App;
